<template>
  <div class="absolute w-screen h-screen">
    <!-- Form -->
    <transition :name="transitionName">
      <card-container v-show="step == 'form'" backButton closeButton>
        <div ref="form" class="relative">
          <!-- Image -->
          <div
            class="relative overflow-hidden bg-cover bg-image-placeholder rounded-t-3xl"
            style="padding-top: 75%"
          >
            <input
              ref="fileInput"
              type="file"
              class="hidden"
              accept="image/*"
              @change="onFilePicked"
            />
            <img
              v-show="imagePreview"
              class="absolute top-0 object-cover w-full h-full"
              :src="imagePreview"
              alt="Location image"
            />
            <div
              v-show="!imagePreview"
              class="absolute top-0 flex flex-col items-center justify-center w-full h-full space-y-4"
            >
              <button
                class="px-6 py-3 text-white border-2 rounded-full focus:outline-none"
                :class="{ 'border-2 border-red-300': validationErrors.image }"
                @click="selectImage()"
              >
                Velg bilde
              </button>
              <div class="text-sm text-center text-white">
                Bør både vise selve plassen og området rundt.<br />
                Unngå personer i bildet.
              </div>
            </div>
            <transition name="slide-bottom-sm">
              <div
                v-show="imagePreview"
                class="absolute bottom-0 w-full p-8 text-center opacity-90"
              >
                <button
                  class="px-5 py-2 text-sm text-white bg-gray-800 rounded-full"
                  @click="selectImage()"
                >
                  Bytt bilde
                </button>
              </div>
            </transition>
          </div>

          <div class="p-5 space-y-6">
            <!-- Title -->
            <div class="mt-4 space-y-2">
              <input
                type="text"
                placeholder="Stedsnavn"
                v-model="location.title"
                class="w-full p-2 text-3xl text-center rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                :class="{ 'border-2 border-red-300': validationErrors.title }"
                @click="validationErrors.title = false"
              />
              <div class="text-sm">
                Navnet bør være så lokalt som mulig. For eksempel vannet plassen ligger ved, en
                liten bukt eller et fjell.
              </div>
            </div>

            <!-- Type -->
            <div ref="sectionType" class="space-y-2">
              <div class="text-xl">Perfekt for</div>
              <div class="space-y-1">
                <button
                  class="w-full px-5 py-3 text-left border-2 rounded-full focus:outline-none"
                  :class="{
                    'border-blue-300 bg-blue-100': featureIsSet('tent'),
                    'border-2 border-red-300': validationErrors.type,
                  }"
                  @click="toggleFeature('tent')"
                >
                  ⛺️ Overnatting i telt
                </button>
                <button
                  class="w-full px-5 py-3 text-left border-2 rounded-full focus:outline-none"
                  :class="{
                    'border-blue-300 bg-blue-100': featureIsSet('hammock'),
                    'border-2 border-red-300': validationErrors.type,
                  }"
                  @click="toggleFeature('hammock')"
                >
                  🍌 Overnatting i hengekøye
                </button>
              </div>
            </div>

            <!-- Features -->
            <div ref="sectionFeatures" class="space-y-2">
              <div class="text-xl">Kort avstand til</div>
              <div class="space-y-1">
                <button
                  class="w-full px-5 py-3 text-left border-2 rounded-full focus:outline-none"
                  :class="{ 'border-blue-300 bg-blue-100': featureIsSet('swimming') }"
                  @click="toggleFeature('swimming')"
                >
                  🏊🏻‍♀️ Bademulighet
                </button>
                <button
                  class="w-full px-5 py-3 text-left border-2 rounded-full focus:outline-none"
                  :class="{ 'border-blue-300 bg-blue-100': featureIsSet('freshwater') }"
                  @click="toggleFeature('freshwater')"
                >
                  💧 Drikkevann
                </button>
                <button
                  class="w-full px-5 py-3 text-left border-2 rounded-full focus:outline-none"
                  :class="{ 'border-blue-300 bg-blue-100': featureIsSet('fishing') }"
                  @click="toggleFeature('fishing')"
                >
                  🐟 Bra fiskeplass
                </button>
                <button
                  class="w-full px-5 py-3 text-left border-2 rounded-full focus:outline-none"
                  :class="{ 'border-blue-300 bg-blue-100': featureIsSet('parking') }"
                  @click="toggleFeature('parking')"
                >
                  🚙 Vei og parkering
                </button>
              </div>
            </div>

            <!-- Description -->
            <div ref="sectionDescription" class="space-y-2">
              <div class="text-xl">Beskrivelse</div>
              <textarea
                v-model="location.description"
                class="w-full p-2 rounded-lg resize-none h-72 focus:outline-none focus:ring focus:border-blue-300"
                :class="{ 'border-2 border-red-300': validationErrors.description }"
                @click="validationErrors.description = false"
              >
              </textarea>
              <div class="text-sm">
                I beskrivelsen kan du for eksempel ta med hvordan man kommer seg til plassen, hvor
                mange telt eller hengekøyer det er plass til, solforhold, om det er mye mygg osv.
              </div>
            </div>

            <!-- Coordinates -->
            <div ref="sectionCoordinates" class="space-y-2">
              <div class="text-xl">Plassering</div>
              <button
                class="w-full px-6 py-3 border-2 rounded-full"
                :class="{ 'border-2 border-red-300': validationErrors.coordinates }"
                @click="setLocationCoordinates()"
              >
                <span v-if="!location.coordinates.latitude">Velg plassering</span>
                <span v-if="location.coordinates.latitude">
                  {{ location.coordinates.latitude.toFixed(5) }}°N
                  {{ location.coordinates.longitude.toFixed(5) }}°E
                </span>
              </button>
              <div class="text-sm">
                Du kan enten finne plassen selv og klikke på kartet, eller hvis du er på mobilen kan
                du bruke din nåværende plassering.
              </div>
            </div>

            <!-- Buttons -->
            <div class="text-center">
              <button
                v-if="!location.id"
                class="p-3 px-12 text-lg border-2 rounded-full"
                @click="saveLocation()"
              >
                Lagre
              </button>
              <button
                v-if="location.id"
                class="p-3 px-12 text-lg border-2 rounded-full"
                @click="updateLocation()"
              >
                Lagre endringer
              </button>
            </div>
          </div>
        </div>
      </card-container>
    </transition>

    <!-- Place marker -->
    <transition :name="transitionName">
      <div
        v-show="step == 'marker'"
        class="absolute w-full space-y-4 text-center pointer-events-none bottom-14"
      >
        <div class="flex justify-center space-x-2">
          <button
            v-if="geolocationSupported && $store.state.isMobile"
            class="px-6 py-2 bg-white border-2 rounded-full pointer-events-auto"
            @click="getDeviceLocation()"
            :disabled="geolocationInProgress"
          >
            <span v-show="!geolocationInProgress">⌖</span>
            <span v-show="geolocationInProgress">⏳</span>
          </button>
          <button
            class="px-12 py-3 text-xl bg-white border-2 rounded-full pointer-events-auto"
            @click.stop="returnToForm()"
            :disabled="geolocationInProgress"
          >
            Ferdig
          </button>
        </div>
      </div>
    </transition>

    <!-- Location error -->
    <transition :name="transitionName">
      <card-container v-show="step == 'locationError'">
        <div class="p-6">
          <div class="mt-8 text-4xl text-center">Hmmm...</div>

          <div class="mt-6 text-base">
            Vi klarte dessverre ikke å finne posisjonen din. Gi nettelseren din tilgang til
            lokasjonsdata og prøv igjen, eller velg plassering manuelt.
          </div>

          <div class="mt-6 text-xl">Apple iOS</div>
          <div class="text-sm">
            Gå til <strong>Innstillinger</strong> &rarr; <strong>Personvern</strong> &rarr;
            <strong>Stedtjenester</strong> og sjekk at <strong>Stedtjenester</strong> er skrudd på.
            Scroll nedover i listen med apper og klikk på <strong>Safari-nettsteder</strong>. Kryss
            av for <strong>Når appen er i bruk</strong> og skru på
            <strong>Nøyaktig posisjon</strong>.
          </div>

          <div class="mt-6 text-xl">Android</div>
          <div class="text-sm">
            I Google Chrome, klikk på de tre prikkene øverst i høyre hjørne og velg
            <strong>Settings</strong> &rarr; <strong>Site Settings</strong> &rarr;
            <strong>Location</strong>. Skru på <strong>Location</strong>.
          </div>

          <div class="mt-12 text-center">
            <button
              class="px-12 py-3 text-xl bg-white border-2 rounded-lg pointer-events-auto"
              @click.stop="setLocationCoordinates()"
            >
              OK
            </button>
          </div>
        </div>
      </card-container>
    </transition>

    <!-- Saving -->
    <transition :name="transitionName">
      <card-container v-show="step == 'save'">
        <div class="flex flex-col items-center justify-center h-full p-5">
          <div class="text-8xl">⏳</div>
          <div class="mt-6 text-xl text-center">{{ asyncStatusMessage }}</div>
        </div>
      </card-container>
    </transition>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import CardContainer from '../components/CardContainer.vue'

export default {
  props: { isNew: Boolean },
  components: { CardContainer },

  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const step = ref('form')
    const transitionName = ref('slide-forward')
    const location = ref()

    const form = ref()
    const sectionType = ref()
    const sectionDescription = ref()
    const sectionCoordinates = ref()
    const validationErrors = ref({})

    const fileInput = ref()
    const imagePreview = ref()

    const geolocationSupported = ref(navigator.geolocation ? true : false)
    const geolocationInProgress = ref(false)

    onBeforeMount(() => {
      if (props.isNew) {
        location.value = {
          image: null,
          title: null,
          features: [],
          description: null,
          coordinates: {},
        }
      } else {
        const l = store.getters.locationById(route.params.id)
        location.value = JSON.parse(JSON.stringify(l))
        imagePreview.value = l.images.full
      }
    })

    onBeforeRouteLeave(() => {
      store.commit('newLocationPos', null)
      if (location.value.id) {
        store.commit('mapAction', {
          action: 'showMarker',
          data: { id: location.value.id },
        })
      }
    })

    const selectImage = () => {
      fileInput.value.click()
      validationErrors.value.image = false
    }

    const onFilePicked = event => {
      if (event.target.value.length === 0) return
      const file = event.target.files[0]
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        imagePreview.value = fileReader.result
      })
      fileReader.readAsDataURL(file)
      location.value.image = file
    }

    const featureIsSet = feature => {
      const i = location.value.features.indexOf(feature)
      return i === -1 ? false : true
    }

    const toggleFeature = feature => {
      if (feature === 'tent' || feature === 'hammock') validationErrors.value.type = false
      const i = location.value.features.indexOf(feature)
      if (i === -1) location.value.features.push(feature)
      else location.value.features.splice(i, 1)
    }

    const setLocationCoordinates = () => {
      step.value = 'marker'
      transitionName.value = 'slide-bottom'
      validationErrors.value.coordinates = false
      store.commit('searchVisible', true)
      if (location.value.id) {
        store.commit('mapAction', {
          action: 'hideMarker',
          data: { id: location.value.id },
        })
        store.commit('newLocationPos', {
          latitude: location.value.coordinates.latitude,
          longitude: location.value.coordinates.longitude,
          flyTo: true,
        })
      }
    }

    const returnToForm = () => {
      const coords = store.getters.newLocationPos
      location.value.coordinates = {
        latitude: coords.latitude,
        longitude: coords.longitude,
      }
      store.commit('searchVisible', false)
      step.value = 'form'
    }

    const getDeviceLocation = () => {
      geolocationInProgress.value = true
      navigator.geolocation.getCurrentPosition(
        position => {
          geolocationInProgress.value = false
          store.commit('newLocationPos', {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            flyTo: true,
          })
        },
        error => {
          console.log('Unable to get geolocation', error)
          geolocationInProgress.value = false
          store.commit('searchVisible', false)
          step.value = 'locationError'
        },
        {
          timeout: 10000,
          maximumAge: 10000,
          enableHighAccuracy: true,
        },
      )
    }

    const formIsValid = () => {
      let scrollTo
      if (!location.value.image && !location.value.images) {
        validationErrors.value.image = true
        scrollTo = 0
      }
      if (!location.value.title) {
        validationErrors.value.title = true
        if (scrollTo === undefined) scrollTo = 0
      }
      if (!featureIsSet('tent') && !featureIsSet('hammock')) {
        validationErrors.value.type = true
        if (scrollTo === undefined) scrollTo = sectionType.value.offsetTop - 20
      }
      if (!location.value.description) {
        validationErrors.value.description = true
        if (scrollTo === undefined) scrollTo = sectionDescription.value.offsetTop - 20
      }
      if (!location.value.coordinates.latitude) {
        validationErrors.value.coordinates = true
        if (scrollTo === undefined) scrollTo = sectionCoordinates.value.offsetTop - 20
      }
      if (scrollTo === undefined) {
        return true
      } else {
        form.value.parentElement.scrollTo({ top: scrollTo, behavior: 'smooth' })
        return false
      }
    }

    const saveLocation = () => {
      if (formIsValid()) {
        console.log('Saving new location')
        step.value = 'save'
        transitionName.value = 'slide-forward'
        store.dispatch('saveLocation', location.value).then(id => {
          router.push({
            name: 'home',
            params: { transition: 'zoom-in' },
          })
          store.commit('selectLocation', id)
        })
      }
    }

    const updateLocation = () => {
      if (formIsValid()) {
        console.log('Updating location')
        step.value = 'save'
        transitionName.value = 'slide-forward'
        store.dispatch('updateLocation', location.value).then(id => {
          router.push({
            name: 'home',
            params: { transition: 'zoom-in' },
          })
          store.commit('selectLocation', id)
        })
      }
    }

    const cancel = () => {
      store.commit('newLocationPos', {})
      router.push('/')
    }

    return {
      step,
      transitionName,
      location,
      form,
      sectionType,
      sectionDescription,
      sectionCoordinates,
      validationErrors,
      fileInput,
      imagePreview,
      geolocationSupported,
      geolocationInProgress,
      selectImage,
      onFilePicked,
      featureIsSet,
      toggleFeature,
      setLocationCoordinates,
      returnToForm,
      getDeviceLocation,
      saveLocation,
      updateLocation,
      cancel,
      asyncStatusMessage: computed(() => store.getters.asyncStatusMessage),
    }
  },
}
</script>

<style>
input:focus::placeholder {
  color: transparent;
}
</style>
